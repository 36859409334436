import React, { useState, useEffect } from 'react'
import { supabase } from "../utils/supabaseClient";
import { useSelector, useDispatch } from 'react-redux';
import {clearGroups, groupAdded, clearYourGroups} from '../store/groupsSlice'
import '../style/views/grouplist.scss'
import '../style/components/groupcard.scss';
import {selectGroupsStore} from "../store/groupsSlice";
import {Link} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    selectThemeStore
} from "../store/themeSlice";
import {Helmet} from "react-helmet-async";

export default function Groups({ session }) {

    const [loading, setLoading] = useState(false)
    const [groupName, setGroupName] = useState('')
    const dispatch = useDispatch();
    const groupsStore = useSelector(selectGroupsStore);
    const [currentPage, setCurrentPage] = useState(1)
    const themeStore = useSelector(selectThemeStore);

    const getPagination = (page, size) => {
        /*TODO: This is hard coded offset for now as we are not treating this as a full pagination system*/
        const offset = 0;
        const limit = size * page - 1;

        return { offset, limit }
    }

        useEffect(() => {
        sanitizeGroups().then(() => {
            getGroups().then()
        })
    }, [currentPage])

    async function sanitizeGroups() {
        setLoading(true)
        try {
            dispatch(clearGroups())
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    function loadMoreGroups() {
        setCurrentPage(currentPage + 1)
    }

    async function getGroups() {
        const { offset, limit } = getPagination(currentPage, 50);

        try {
            setLoading(true)

            let { data, error, status } = await supabase
                .from('groups')
                .select(`*`)
                .range(offset, limit);

            if (error && status !== 406) {
                throw error
            }

            if (data) {
                for (let group in data) {

                    let groupedData = await supabase
                        .from('group_topic_relations')
                        .select('*, group_topics!inner(*)')
                        .eq('group_id', data[group].group_id)

                    let groupTopicsArray = []

                    for (let tagIndex in groupedData.data) {
                        groupTopicsArray.push(groupedData.data[tagIndex].group_topics)
                    }

                    dispatch(
                        groupAdded({
                            groupId: data[group].group_id,
                            groupUrl: data[group].group_url,
                            groupName: data[group].group_name,
                            groupTags: groupTopicsArray,
                            createdAt: data[group].created_at,
                            createdBy: data[group].created_by
                        })
                    )
                }
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    async function groupValidation() {

        let validationPassed = false

        if (groupName.length > 2) {
            validationPassed = true;
        }
        let groupUrl = groupName.replace(/\s+/g, '-').toLowerCase();
        console.log(groupUrl)


        if (validationPassed) {
            try {
                let { data } = await supabase
                    .from('groups')
                    .select('group_name')
                    .eq('group_url', groupUrl)
                console.log("data", data)
                if (data.length === 0) {
                    await createGroup(groupName, groupUrl)
                } else {
                    notify()
                }
            } catch(error) {
                alert("Error message", error)
            }
        } else {
            console.log("Group name is too short")
        }
    }

    async function createGroup(groupName, groupUrl) {
        const user = supabase.auth.user()

        try {
            let { data } = await supabase
                .from('groups')
                .insert([{ group_name: groupName, created_by: user.id, group_url: groupUrl}])
            let groupId = data[0].group_id
            await supabase
                .from('group_access')
                .insert([{ group_id: groupId, uuid: session.user.id,  group_admin: true}])
            dispatch(
                clearYourGroups()
            )
            return data
        }
        catch (error) {
            console.log("Group was not created", error)
        } finally {
            setGroupName(null)
        }
    }

    const reactiveDarkMode = () => {
        return themeStore.darkMode ? 'dark' : 'light'
    }

    const notify = () => toast('Please chose a different name', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: reactiveDarkMode
    });

    const groupTags = (type, index) => {
        switch (type) {
            case 'all':
                return groupsStore.groups[index].groupTags.map((tag, index) => {
                    return (
                        <div key={index} className="tag">
                            {tag.group_tag}
                        </div>
                    )
                })
            case 'user':
                return groupsStore.yourGroups[index].groupTags.map((tag, index) => {
                    return (
                        <div key={index} className="tag">
                            {tag.group_tag}
                        </div>
                    )
                })
            default:
                return null
        }
    }

    const yourGroupsList = groupsStore.yourGroups.map((group, index) => (
        <Link to={`${group.groupUrl}`} className={"group-card"} key={group.groupId} state={{ groupId: group.groupId }}>
            <div>{group.groupName}</div>
            <div className={"tag-wrapper"}>{groupTags("user", index)}</div>
        </Link>
    ))

    const groupsList = groupsStore.groups.map((group, index) => (
        <Link to={`${group.groupUrl}`} className={"group-card"} key={group.groupId} state={{ groupId: group.groupId }}>
            <div>{group.groupName}</div>
            <div className={"tag-wrapper"}>{groupTags("all", index)}</div>
        </Link>
    ))

    return (
        <div className="container">
            <Helmet>
                <title>Groups</title>s
            </Helmet>
            {groupsStore.yourGroups.length > 0 ? <h3>Your groups</h3> : ''}
            <div className={"group-card-wrapper"}>
                {yourGroupsList}
            </div>

            <h3>Discover all groups</h3>
            <div className={"group-card-wrapper"}>
                {groupsList}
                {/*<button className="button-secondary" onClick={loadMoreGroups}>Load more</button>*/}
            </div>
            <div className={"bottom-bar"}>
                <input
                    id="newGroup"
                    type="text"
                    value={groupName || ''}
                    onChange={(e) => setGroupName(e.target.value)}
                />
                <button
                    className="button-primary"
                    onClick={() => groupValidation({groupName})}
                    disabled={loading}
                >
                    {loading ? 'Loading ...' : 'Create group'}
                </button>
            </div>
            <ToastContainer
                newestOnTop={false}
                pauseOnFocusLoss
                draggable
                limit={2}
            />
        </div>
    )
}