import React, { useState, useEffect } from 'react'
import { supabase } from '../utils/supabaseClient'
import VideoCard from "../components/VideoCard";
import '../style/views/watchlist.scss'
import '../style/components/bottom-bar.scss';
import '../style/components/tab-bar.scss';

import { useSelector, useDispatch } from 'react-redux';
import {
    videoAdded,
    videoWatched,
    clearVideos,
    clearVideosWatched,
    selectVideoStore,
} from '../store/videosSlice'
import {selectGroupsStore} from "../store/groupsSlice";
import {selectMetaDataStore} from "../store/metaDataSlice";

export default function Watchlist({ session }) {
    /*Local Store*/
    const [loading, setLoading] = useState(true)
    const [showWatched, setShowWatched] = useState(false)
    const [sortingType, setSortingType] = useState('created_at')
    /*Global Store*/
    const videoStore = useSelector(selectVideoStore);
    const groupsStore = useSelector(selectGroupsStore);
    const metaDataStore = useSelector(selectMetaDataStore);
    const dispatch = useDispatch();

    useEffect(() => {
        getVideos("created_at", false).then(r => setLoading(false))
    }, [session, groupsStore, metaDataStore, showWatched, videoStore.videosWatched]);

    async function getVideos(sorting, clearStore) {

        if (groupsStore.yourGroupIds.length > 0) {
            let sortingQuery

            /*We need to clear the local storage in case a new sorting is called due to pagination */
            if (clearStore && showWatched) {
                dispatch(
                    clearVideosWatched()
                )
            } else if (clearStore && !showWatched) {
                dispatch(
                    clearVideos()
                )
            }

            try {

                const user = supabase.auth.user()

                if (sorting && sorting === 'video_url') {
                    sortingQuery = 'video_url'
                    setSortingType('video_url')
                } else if (sorting && sorting === 'avg_score') {
                    sortingQuery = 'avg_score'
                    setSortingType('avg_score')
                } else {
                    sortingQuery = 'created_at'
                    setSortingType('created_at')
                }

                const { data } = await supabase
                    .from('videos')
                    .select(`
                    *,
                    groups!inner(group_id), video_user_data!inner(*)
                  `)
                    .in('group', groupsStore.yourGroupIds)
                    .order(sortingQuery, { ascending: false })

                for (let video in data) {

                    let metaDataEntryExists = data[video].video_user_data.find( entry => entry['uuid'] === user.id );

                    let metaDataVideoWatched = false
                    let metaDataRatingScore = false

                    if (metaDataEntryExists !== undefined) {
                        metaDataVideoWatched = metaDataEntryExists.watched
                        metaDataRatingScore = metaDataEntryExists.rating_score
                    } else {
                        metaDataVideoWatched = false
                        metaDataRatingScore = null
                    }

                    if (showWatched && metaDataVideoWatched) {
                        dispatch(
                            videoWatched({
                                id: data[video].id,
                                videoName: data[video].video_url,
                                videoWatched: metaDataVideoWatched,
                                ratingScore: metaDataRatingScore,
                                createdAt: data[video].created_at
                            })
                        )
                    } else if (!metaDataVideoWatched) {
                        dispatch(
                            videoAdded({
                                id: data[video].id,
                                videoName: data[video].video_url,
                                videoWatched: metaDataVideoWatched,
                                ratingScore: metaDataRatingScore,
                                createdAt: data[video].created_at
                            })
                        )
                    }

                }

            } catch (error) {
                console.log("an error occurred", error)
            } finally {
            }
        }
    }

    const StateVideoList = videoStore.videos.map((video) => (
        <VideoCard isCard={true} key={video.id} session={session} embedId={video.videoName} videoId={video.id} videoWatched={video.videoWatched} createdAt={video.createdAt} ratingScore={video.ratingScore} />
    ))

    const StateVideoWatchedList = videoStore.videosWatched.map((video) => (
        <VideoCard isCard={true} key={video.id} session={session} embedId={video.videoName} videoId={video.id} videoWatched={video.videoWatched} createdAt={video.createdAt} ratingScore={video.ratingScore} />
    ))

    class VideoList extends React.Component {
        render() {
            return showWatched ? <div className="video-wrapper">{StateVideoWatchedList}</div> : <div className="video-wrapper">{StateVideoList}</div>
        }
    }

    class VideoEmptyState extends React.Component {
        render() {
            return (!loading && videoStore.videosWatched.length === 0 && videoStore.videos.length === 0) ? <div className="card"><h1>Get started</h1><p>Discover a group to find videos you wanna watch.</p></div> : ''
        }
    }

    function watchButtonClass (type) {
        return showWatched === type ? 'button-secondary button-secondary--highlight' : 'button-secondary'
    }

    function sortButtonClass (type) {
        return sortingType === type ? 'button-secondary button-secondary--highlight' : 'button-secondary'
    }

    return (
        <div className="container">
                <div className="tabs">
                    <div className={"tabs__el"}>
                        <button type={"button"} className={sortButtonClass("created_at")} onClick={() => getVideos("created_at", true)}>Latest</button>
                        {/*For now A - Z doesn't make a lot of sense, was just used for testing purposes*/}
                        {/*<button type={"button"} className={sortButtonClass("video_url")} onClick={() => getVideos("video_url", true)}>A - Z</button>*/}
                        <button type={"button"} className={sortButtonClass("avg_score")} onClick={() => getVideos("avg_score", true)}>Score</button>
                    </div>
                    <div className={"tabs__el"}>
                        <button type={"button"} className={watchButtonClass(false)} onClick={() => setShowWatched(false)}>New</button>
                        <button type={"button"} className={watchButtonClass(true)} onClick={() => setShowWatched(true)}>Watched</button>
                    </div>
                </div>
            <VideoList />
            <VideoEmptyState />
        </div>
    )
}