import React, {useState} from 'react'
import '../style/components/bottom-bar.scss';
import {supabase} from "../utils/supabaseClient";
import {
    setReloadVideos
} from "../store/videosSlice";
import {useDispatch} from "react-redux";
export default function AddVideoBar({ isAdmin, passedGroupId }) {

    const dispatch = useDispatch()
    const [newVideo, setNewVideo] = useState(null)
    const [loading, setLoading] = useState(false)

    async function videoValidation() {
        setLoading(true)
        let newVideoSanitized
        let validationPassed = false

        if (newVideo.length > 3) {
            if (newVideo.includes('youtu.be')) {
                newVideoSanitized =
                    newVideoSanitized = newVideo.substring(newVideo.indexOf(".be/") + 4);
                validationPassed = true;
            }
            else if (newVideo.lastIndexOf('http', 0) === 0) {
                newVideoSanitized =
                    newVideoSanitized = newVideo.substring(newVideo.indexOf("=") + 1);
                validationPassed = true;
            } else {
                newVideoSanitized = newVideo
                validationPassed = true;
            }
        }

        if (validationPassed) {
            try {
                let { data } = await supabase
                    .from('videos')
                    .select('video_url')
                    .match({'video_url': newVideoSanitized, 'group': passedGroupId})
                console.log("data", data)
                if (data.length === 0) {
                    await addVideo(newVideoSanitized)
                } else {
                    alert("Video has been added already")
                }
            } catch(error) {
                alert("Error message", error)
            }
        }
    }

    async function addVideo(newVideoSanitized) {
        const user = supabase.auth.user()

        try {
            let { data } = await supabase
                .from('videos')
                .insert([{ video_url: newVideoSanitized, created_by: user.id, group: passedGroupId, score_sum: 0, score_count: 0 }])
            await addVideoUserData(data[0].id)
        }
        catch (error) {
            console.log("Video was not added", error)
        }
    }

    async function addVideoUserData(videoDataId) {
        const user = supabase.auth.user()
        try {
            let { data } = await supabase
                .from('video_user_data')
                .insert([{ video_id: videoDataId, uuid: user.id, watched: false, rating_score: 0}])
            return data
        }
        catch (error) {
            console.log("Video User Data was not added", error)
        } finally {
            setNewVideo(null)
            setLoading(false)
            dispatch(
                setReloadVideos(true)
            )
        }
    }

    return (
        <div className={"bottom-bar"}>
            <input
                id="newVideo"
                type="text"
                placeholder={'Add Youtube URL or Embed Id'}
                value={newVideo || ''}
                onChange={(e) => setNewVideo(e.target.value)}
            />

            <button
                className="button-primary"
                onClick={() => videoValidation({newVideo})}
                disabled={loading}
            >
                {loading ? 'Loading ...' : 'Add video'}
            </button>
        </div>
    )
}