import {useParams} from 'react-router-dom'
import {supabase} from "../utils/supabaseClient";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectVideoStore, clearVideoGroup, videoGroup, setReloadVideos} from "../store/videosSlice";
import {clearCurrentGroup, selectGroupsStore, currentGroupUpdate, clearYourGroups} from "../store/groupsSlice";
import VideoCard from "../components/VideoCard";
import AddVideoBar from "../components/AddVideoBar";
import '../style/components/list.scss'
import '../style/components/modal.scss'
import '../style/components/meta-data.scss'
import Moment from "moment";
import Modal from 'react-modal';
import {Helmet} from "react-helmet-async";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {selectThemeStore} from "../store/themeSlice";
import {useLocation} from "react-router-dom";

export default function Group({session}) {

    Modal.setAppElement(document.getElementById('root'));

    let params = useParams();
    const [loading, setLoading] = useState(true)
    const [videoIds, setVideoIds] = useState([])
    const [isAdmin, setIsAdmin] = useState(false)
    const [isMember, setIsMember] = useState(false)
    const [members, setMembers] = useState([])
    const [users, setUsers] = useState([])
    const [newDesc, setNewDesc] = useState('')
    const [newName, setNewName] = useState('')
    const [newTopic, setNewTopic] = useState('')
    const [groupTopics, setGroupTopics] = useState([])
    const videoStore = useSelector(selectVideoStore)
    const groupStore = useSelector(selectGroupsStore)
    const themeStore = useSelector(selectThemeStore)
    const dispatch = useDispatch()
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalDescIsOpen, setDescIsOpen] = React.useState(false);
    const [modalNameIsOpen, setNameIsOpen] = React.useState(false);
    const [modalDeleteIsOpen, setDeleteIsOpen] = React.useState(false);
    const [modalTopicIsOpen, setTopicIsOpen] = React.useState(false);
    const [sortingType, setSortingType] = useState('created_at')
    const location = useLocation();
    const {groupId} = location.state;

    useEffect(() => {
        getVideos("created_at").then(r => setLoading(false))
    }, [session, videoStore.reloadVideos])

    useEffect(() => {
        getGroupInfo().then(r => setLoading(false))
        getGroupMembers().then(r => setLoading(false))
        getAllUsers().then(r => setLoading(false))
    }, [session]);

    useEffect(() => {
        getGroupTopic().then(r => setLoading(false))
    }, [session, modalTopicIsOpen]);

    const reactiveDarkMode = () => {
        return themeStore.darkMode ? 'dark' : 'light'
    }

    const notify = (msg) => toast(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: reactiveDarkMode
    });

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        console.log("modal is opened")
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openModalDesc() {
        setDescIsOpen(true);
    }

    function afterOpenModalDesc() {
        console.log("modal is opened")
    }

    function closeModalDesc() {
        setDescIsOpen(false);
    }

    function openModalName() {
        setNameIsOpen(true);
    }

    function afterOpenModalName() {
        console.log("modal is opened")
    }

    function closeModalName() {
        setNameIsOpen(false);
    }

    function openModalDelete() {
        setDeleteIsOpen(true);
    }

    function afterOpenModalDelete() {
        console.log("modal is opened")
    }

    function closeModalDelete() {
        setDeleteIsOpen(false);
    }

    function openModalTopic() {
        setTopicIsOpen(true);
    }

    function afterOpenModalTopic() {
        console.log("modal is opened")
    }

    function closeModalTopic() {
        setTopicIsOpen(false);
    }

    async function getGroupInfo() {
        setLoading(true)
        dispatch(clearCurrentGroup())

        try {
                const {data} = await supabase
                    .from('groups')
                    .select('*, group_access!inner(uuid, group_admin)')
                    .eq('group_id', groupId)

            for (let count in data) {
                dispatch(
                    currentGroupUpdate({
                        groupId: data[count].group_id,
                        groupUrl: data[count].group_url,
                        groupName: data[count].group_name,
                        createdBy: data[count].created_by,
                        createdAt: data[count].created_at,
                        groupDesc: data[count].group_desc
                    })
                )
                setNewDesc(data[count].group_desc)
                setNewName(data[count].group_name)
            }

        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            setLoading(false)
        }
    }

    async function getAllUsers() {
        setUsers([])
        try {

            const {data} = await supabase
                .from('profiles')
                .select('*')

            for (let count in data) {
                setUsers(users => [...users, data[count]])
            }

        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            setLoading(false)
        }
    }

    async function getGroupTopic() {
        setGroupTopics([])
        try {

            const {data} = await supabase
                .from('group_topic_relations')
                .select('*, group_topics!inner(*)')
                .eq('group_id', groupId)

            for (let count in data) {
                setGroupTopics(topics => [...topics, data[count]])
            }

        } catch (error) {
            console.log("an error occurred", error)
        }
    }

    async function getGroupMembers() {
        setLoading(true)
        setMembers([])
        setIsAdmin(false)
        setIsMember(false)

        try {
            const user = supabase.auth.user()

            const {data} = await supabase
                .from('group_access')
                .select('*, uuid!inner(*)')
                .eq('group_id', groupId)

            for (let count in data) {
                setMembers(members => [...members, data[count]])
                if (data[count].uuid.id === user.id && data[count].group_admin) setIsAdmin(true)
                else if (data[count].uuid.id === user.id && !data[count].group_admin) setIsMember(true)
            }

        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            setLoading(false)
        }
    }

    async function getVideos(sorting) {

        let sortingQuery
        setReloadVideos(false)
        dispatch(
            clearVideoGroup()
        )

        try {
            const user = supabase.auth.user()

            if (sorting && sorting === 'video_url') {
                sortingQuery = 'video_url'
                setSortingType('video_url')
            } else if (sorting && sorting === 'avg_score') {
                sortingQuery = 'avg_score'
                setSortingType('avg_score')
            } else {
                sortingQuery = 'created_at'
                setSortingType('created_at')
            }

            const {data} = await supabase
                .from('videos')
                .select(`
                    *,
                    groups!inner(group_id), video_user_data!inner(*)
                  `)
                .eq('group', groupId)
                .order(sortingQuery, {ascending: false})

            let videoIds = []

            for (let video in data) {

                let metaDataEntryExists = data[video].video_user_data.find(entry => entry['uuid'] === user.id);

                let metaDataVideoWatched = false
                let metaDataRatingScore = false

                if (metaDataEntryExists !== undefined) {
                    metaDataVideoWatched = metaDataEntryExists.watched
                    metaDataRatingScore = metaDataEntryExists.rating_score
                } else {
                    metaDataVideoWatched = false
                    metaDataRatingScore = null
                }

                dispatch(
                    videoGroup({
                        id: data[video].id,
                        videoName: data[video].video_url,
                        createdAt: data[video].created_at,
                        videoWatched: metaDataVideoWatched,
                        ratingScore: metaDataRatingScore
                    })
                )

                videoIds.push(data[video].id)
            }

            setVideoIds(videoIds)

        } catch (error) {
            console.log("an error occurred", error)
        }
    }

    async function deleteGroupAccess() {
        try {
            await supabase
                .from('group_access')
                .delete()
                .match({'group_id': groupId})
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await deleteVideoUserData()
        }
    }

    async function deleteVideoUserData() {
        try {
            await supabase
                .from('video_user_data')
                .delete()
                .in('video_id', videoIds)
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await deleteVideos()
        }
    }

    async function deleteVideos() {
        try {
            await supabase
                .from('videos')
                .delete()
                .in('id', videoIds)
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await deleteGroup()
        }
    }

    async function deleteGroup() {
        try {
            await supabase
                .from('groups')
                .delete()
                .eq('group_id', groupId)
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            window.location.href = "/groups"
        }
    }

    async function joinGroup() {
        const user = supabase.auth.user()
        try {
            dispatch(
                clearYourGroups()
            )
            await supabase
                .from('group_access')
                .insert([{group_id: groupId, uuid: user.id, group_admin: false}])
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await getGroupMembers()
        }
    }

    async function leaveGroup() {
        const user = supabase.auth.user()
        try {
            dispatch(
                clearYourGroups()
            )
            await supabase
                .from('group_access')
                .delete()
                .match({'group_id': groupId, 'uuid': user.id})
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            getGroupMembers()
        }
    }

    async function removeMember(memberId) {
        try {
            await supabase
                .from('group_access')
                .delete()
                .match({'group_id': groupId, 'uuid': memberId})
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await getGroupMembers()
        }
    }

    async function grantAdminRights(memberId) {
        try {
            await supabase
                .from('group_access')
                .update([{group_admin: true}])
                .match({'group_id': groupId, 'uuid': memberId})
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await getGroupMembers()
        }
    }

    async function revokeAdminRights(memberId) {
        try {
            await supabase
                .from('group_access')
                .update([{group_admin: false}])
                .match({'group_id': groupId, 'uuid': memberId})
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await getGroupMembers()
        }
    }

    async function updateDesc() {
        try {
            await supabase
                .from('groups')
                .update([{group_desc: newDesc}])
                .match({'group_id': groupId})
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await getGroupInfo()
            closeModalDesc()
        }
    }

    async function updateName() {
        try {
            await supabase
                .from('groups')
                .update([{group_name: newName}])
                .match({'group_id': groupId})
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await getGroupInfo()
            closeModalName()
        }
    }

    async function updateTopic() {
        if (newTopic.length > 3) {
            let newTopicSanitized = newTopic.toLowerCase();
            try {
                let {data} = await supabase
                    .from('group_topics')
                    .select('group_tag')
                    .match({'group_tag': newTopicSanitized})
                if (data.length === 0) {
                    await addTopic(newTopicSanitized)
                } else {
                    notify("This topic already exists")
                }
            } catch (error) {
                notify("We couldn't add your topic", error)
            }
        } else {
            notify("A topic must be at least 3 characters long")
        }
    }

    async function addTopic(newTopicSanitized) {

        try {
            let {data} = await supabase
                .from('group_topics')
                .insert([{group_tag: newTopicSanitized}])
            await addGroupTopicRelations(data[0].id)
        } catch (error) {
            notify("We couldn't add your topic", error)
        }
    }

    async function addGroupTopicRelations(topicId) {
        try {
            await supabase
                .from('group_topic_relations')
                .insert([{group_id: groupId, topic_id: topicId}])
        } catch (error) {
            notify("We couldn't add your topic", error)
        } finally {
            closeModalTopic()
        }
    }

    async function removeTopic(topicId) {
        try {
            await supabase
                .from('group_topic_relations')
                .delete()
                .eq('topic_id', topicId)
        } catch (error) {
            notify("We couldn't remove your topic", error)
        }
    }

    class GroupName extends React.Component {
        render() {
            return (groupStore.currentGroup[0] && groupStore.currentGroup[0].groupName) ? groupStore.currentGroup[0].groupName : '...'
        }
    }

    /*Buttons*/

    /*Admin Rights*/

    class AddMemberButton extends React.Component {
        render() {
            return (isAdmin) ? <button className="button-primary" onClick={openModal}>Add Member</button> : ''
        }
    }

    class ChangeGroupNameButton extends React.Component {
        render() {
            return (isAdmin) ? <button className="button-hidden" onClick={openModalName}>Edit</button> : ''
        }
    }

    class SetDescButton extends React.Component {
        render() {
            return (isAdmin) ? <button className="button-hidden" onClick={openModalDesc}>Edit</button> : ''
        }
    }

    class SetTopicButton extends React.Component {
        render() {
            return (isAdmin) ? <button className="button-hidden" onClick={openModalTopic}>Edit</button> : ''
        }
    }

    class DeleteGroupButton extends React.Component {
        render() {
            return (isAdmin) ? <button className="button-secondary" onClick={openModalDelete}>Delete Group</button> : ''
        }
    }

    class RenderVideoBar extends React.Component {
        render() {
            return (isAdmin) ? <AddVideoBar isAdmin={isAdmin} passedGroupId={groupId}/> : ''
        }
    }

    /*Member Rights*/

    class LeaveGroupButton extends React.Component {
        render() {
            return (isMember) ? <button className="button-primary" onClick={leaveGroup}>Leave Group</button> : ''
        }
    }

    /*Other Rights*/

    class JoinGroupButton extends React.Component {
        render() {
            return (!isMember && !isAdmin) ?
                <button className="button-primary" onClick={joinGroup}>Join Group</button> : ''
        }
    }

    const videoList = videoStore.videoGroup.map((video) => (
        <VideoCard isCard={true} key={video.id} groupId={parseInt(groupId)} session={session} embedId={video.videoName}
                   videoId={video.id} createdAt={video.createdAt} ratingScore={video.ratingScore}/>
    ))

    const memberList = members.map((member) => (
        <div className={'entry'} key={member.uuid.id}>
            <span>{member.uuid.username ? member.uuid.username : 'Anonymous'} {member.group_admin ? '(Admin)' : '(Member)'}</span>
            <div className={'flex'}>
                {!member.group_admin && isAdmin ?
                    <button className="button-secondary" onClick={() => grantAdminRights(member.uuid.id)}>Make
                        Admin</button> : ''}
                {!member.group_admin && isAdmin ?
                    <button className="button-secondary" onClick={() => removeMember(member.uuid.id)}><img
                        src="/icons/delete.svg" alt="Delete Button"/></button> : ''}
                {member.group_admin && isAdmin && member.uuid.id !== session.user.id ?
                    <button className="button-secondary" onClick={() => revokeAdminRights(member.uuid.id)}>Revoke
                        Rights</button> : ''}
            </div>
        </div>
    ))

    const userList = users.map((user) => (
        <div key={user.id}>{user.username}</div>
    ))

    const topicList = groupTopics.map((topic) => (
        <div className={'tag'} key={topic.id}>
            <span>#{topic.group_topics.group_tag}</span>
            <button className={"button-hidden"} onClick={() => removeTopic(topic.topic_id)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                     className="icon-stroke">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
            </button>
        </div>
    ))

    class ReturnDate extends React.Component {
        render() {
            Moment.locale('en');
            let dt = (groupStore.currentGroup[0] && groupStore.currentGroup[0].createdAt) ? groupStore.currentGroup[0].createdAt : null;
            if (dt !== null) {
                return (<span className={"label"}>created on {Moment(dt).format('DD.MM.YYYY')}</span>)
            } else return ('')
        }
    }

    function sortButtonClass(type) {
        return sortingType === type ? 'button-secondary button-secondary--highlight' : 'button-secondary'
    }

    return (
        <div className={"container container--flex"}>
            <Helmet>
                <title>{groupStore && groupStore.currentGroup[0] && groupStore.currentGroup[0].groupName ? 'Glimpse | ' + groupStore.currentGroup[0].groupName : ''}</title>
            </Helmet>
            <div className="tabs">
                <div className={"tabs__el"}>
                    <button type={"button"} className={sortButtonClass("created_at")}
                            onClick={() => getVideos("created_at")}>Latest
                    </button>
                    <button type={"button"} className={sortButtonClass("avg_score")}
                            onClick={() => getVideos("avg_score")}>Best rated
                    </button>
                </div>
            </div>
            <div className={"container-row container-row--fill"}>
                <div className={"video-wrapper"}>
                    {videoList}
                </div>
            </div>
            <div className={"container-row container-row--small"}>
                <div className={"card card--sticky"}>
                    <div className={"flex flex--spaced flex--baseline"}>
                        <h3><GroupName/></h3>
                        <ChangeGroupNameButton/>
                    </div>
                    <div className={"meta-data"}><span
                        className={"label"}>{videoStore.videoGroup.length} videos</span><span
                        className={"label"}> · </span><span className={"label"}>{members.length} members</span><span
                        className={"label"}> · </span><ReturnDate/></div>
                    <div className={"flex flex--spaced flex--baseline"}>
                        <div className={"tag-wrapper"}>{topicList}</div>
                        <SetTopicButton/>
                    </div>
                    <div className={"flex flex--spaced flex--baseline"}>
                        <p className={"p-sml"}>{groupStore && groupStore.currentGroup[0] && groupStore.currentGroup[0].groupDesc ? groupStore.currentGroup[0].groupDesc : 'No Description'}</p>
                        <SetDescButton/>
                    </div>

                    <div className={"button-wrapper button-wrapper--flex"}>
                        {/*
                        <AddMemberButton />
*/}
                        <DeleteGroupButton/>
                        <LeaveGroupButton/>
                        <JoinGroupButton/>
                    </div>
                    {(isMember || isAdmin) ? <div className={'list'}>{memberList}</div> : ''}
                </div>
            </div>

            <RenderVideoBar/>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                contentLabel="Invite Users"
                closeTimeoutMS={200}
            >
                <h2>Invite users</h2>
                {userList}
                <button onClick={closeModal}>close</button>
                <form>
                    <input/>
                    <button>tab navigation</button>
                    <button>stays</button>
                    <button>inside</button>
                    <button>the modal</button>
                </form>
            </Modal>

            <Modal
                isOpen={modalDescIsOpen}
                onAfterOpen={afterOpenModalDesc}
                onRequestClose={closeModalDesc}
                contentLabel="Desc Modal"
                className={"modal-overlay"}
                closeTimeoutMS={200}
            >
                <div className={"modal"}>
                    <h2>Change your group description</h2>

                    <textarea id="newDesc"
                              name="Group Description"
                              rows="4"
                              cols="50"
                              placeholder={newDesc || "What's this group all about?"}
                              value={newDesc || ''}
                              onChange={(e) => setNewDesc(e.target.value)}>
                    </textarea>

                    <div className={"button-wrapper"}>
                        <button className="button-secondary" onClick={closeModalDesc}>Close</button>
                        <button
                            className="button-primary"
                            onClick={() => updateDesc()}
                            disabled={loading}
                        >
                            {loading ? 'Loading ...' : 'Update'}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalNameIsOpen}
                onAfterOpen={afterOpenModalName}
                onRequestClose={closeModalName}
                contentLabel="Name Modal"
                className={"modal-overlay"}
                closeTimeoutMS={200}
            >
                <div className={"modal"}>
                    <h2>Change your group name</h2>
                    <input
                        id="newName"
                        type="text"
                        placeholder={'New name for your group'}
                        value={newName || ''}
                        onChange={(e) => setNewName(e.target.value)}
                    />

                    <div className={"button-wrapper"}>
                        <button className="button-secondary" onClick={closeModalName}>Close</button>
                        <button
                            className="button-primary"
                            onClick={() => updateName()}
                            disabled={loading}
                        >
                            {loading ? 'Loading ...' : 'Save'}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalDeleteIsOpen}
                onAfterOpen={afterOpenModalDelete}
                onRequestClose={closeModalDelete}
                contentLabel="Delete Modal"
                className={"modal-overlay"}
                closeTimeoutMS={200}
            >
                <div className={"modal"}>
                    <h2>Do you really want to delete your group?</h2>
                    <p>This will delete the group for all members and remove all videos and scores added. This action is
                        irreversible.</p>
                    <div className={"button-wrapper"}>
                        <button className="button-secondary" onClick={closeModalDelete}>Cancel</button>
                        <button
                            className="button-primary"
                            onClick={() => deleteGroupAccess()}
                            disabled={loading}
                        >
                            {loading ? 'Loading ...' : 'Delete group'}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalTopicIsOpen}
                onAfterOpen={afterOpenModalTopic}
                onRequestClose={closeModalTopic}
                contentLabel="Topic Modal"
                className={"modal-overlay"}
                closeTimeoutMS={200}
            >
                <div className={"modal"}>
                    <h2>Set your group topics</h2>
                    <input
                        id="newName"
                        type="text"
                        placeholder={'Add a new topic'}
                        value={newTopic || ''}
                        onChange={(e) => setNewTopic(e.target.value)}
                    />

                    <div className={"button-wrapper"}>
                        <button className="button-secondary" onClick={closeModalTopic}>Close</button>
                        <button
                            className="button-primary"
                            onClick={() => updateTopic()}
                            disabled={loading}
                        >
                            {loading ? 'Loading ...' : 'Save'}
                        </button>
                    </div>
                </div>
            </Modal>

            <ToastContainer
                newestOnTop={false}
                pauseOnFocusLoss
                draggable
                limit={1}
            />
        </div>
    )
}