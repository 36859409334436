import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({

    name: 'metaDataStore',
    initialState: {
        videoIds: [],
    },
    reducers: {
        setMetaData(state, action) {
            let idInArray = state.videoIds.find( id => id['videoId'] === action.payload.videoId );
            if (!idInArray) {
                state.videoIds.push(action.payload)
            }
        },
    },
});

export const { setMetaData } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectMetaDataStore = state => state.metaDataStore;

export default slice.reducer;
