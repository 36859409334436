import React, { useState, useEffect } from 'react'
import { supabase } from '../utils/supabaseClient'
import { Link, NavLink } from "react-router-dom";
import '../style/components/nav.scss';
import {useDispatch} from "react-redux";
import { setTheme } from "../store/themeSlice";
import { useMediaQuery } from 'react-responsive';

export default function Nav({ session }) {
    const [avatarPath, setAvatarPath] = useState(null)
    const [avatarUrl, setAvatarUrl] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [prefersDarkScheme, setPrefersDarkScheme] = useState(false)
    const [darkMode, setDarkMode] = useState(false)
    let currentTheme = localStorage.getItem("theme");
    const dispatch = useDispatch()
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });


    useEffect(() => {
        getProfile().then()
    }, [session])

    useEffect(() => {
        if (avatarUrl) downloadImage(avatarUrl).then()
    }, [avatarUrl])

    useEffect(() => {
        setDisplayMode()
    }, [session])

    async function downloadImage(path) {
        try {
            const { data, error } = await supabase.storage.from('avatars').download(path)
            if (error) {
                throw error
            }
            const url = URL.createObjectURL(data)
            setAvatarPath(url)
        } catch (error) {
            console.log('Error downloading image: ', error.message)
        }
    }

    async function getProfile() {
        try {
            const user = supabase.auth.user()

            let { data, error, status } = await supabase
                .from('profiles')
                .select(`avatar_url`)
                .eq('id', user.id)

            if (error && status !== 406) {
                throw error
            }

            if (data && data[0] && data[0].avatar_url) {
                setAvatarUrl(data[0].avatar_url)
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setIsLoaded(true)
        }
    }

    function setDisplayMode() {
        setPrefersDarkScheme(window.matchMedia("(prefers-color-scheme: dark)").matches)
        if (currentTheme === "dark") {
            dispatch(setTheme(true))
            setDarkMode(true)
            document.body.classList.toggle("dark-theme");
        } else if (currentTheme === "light") {
            document.body.classList.toggle("light-theme");
        } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            dispatch(setTheme(true))
            setDarkMode(true)
            document.body.classList.toggle("dark-theme");
        } else {
            document.body.classList.toggle("light-theme");
        }
    }

    const toggleMode = () => {
        let theme
        if (prefersDarkScheme) {
            document.body.classList.toggle("light-theme");
            theme = document.body.classList.contains("light-theme")
                ? "light"
                : "dark";
        } else {
            document.body.classList.toggle("dark-theme");
            theme = document.body.classList.contains("dark-theme")
                ? "dark"
                : "light";
        }
        localStorage.setItem("theme", theme);
        currentTheme = localStorage.getItem("theme");
        if (currentTheme === "dark") {
            dispatch(setTheme(true))
            setDarkMode(true)
        } else if (currentTheme === "light") {
            dispatch(setTheme(false))
            setDarkMode(false)
        }
    }

    class NavStructure extends React.Component {
        render() {
            return isLoaded ?
                <nav>
                <Link to="/">
                    <svg width="43" height="24" viewBox="0 0 43 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M21.2903 0C11.8112 0 0 7.83871 0 12C0 16.0645 11.8112 24 21.2903 24C30.7694 24 42.5806 16.1613 42.5806 12C42.5806 7.93548 30.7694 0 21.2903 0ZM25.5026 6.89756L22.279 8.75871C21.6204 8.06827 20.6786 8.00017 19.6805 8.5764C18.0745 9.50367 17.8489 11.2324 19.0245 13.2686C20.2167 15.3336 21.7677 16.0595 23.4196 15.1057C24.8479 14.2811 25.1931 13.2099 24.6323 12.1724L22.4985 13.4043L21.1871 11.1329L26.3954 8.12588L27.3359 9.7549C29.1904 12.967 28.074 16.1201 24.9995 17.8952C21.5694 19.8755 17.916 18.9257 15.77 15.2088C13.5313 11.3313 14.8426 7.66797 18.0089 5.83993C20.7851 4.23709 23.8949 4.68248 25.5026 6.89756Z" className={"logo-path"}/>
                    </svg>
                </Link>
                <div className={"flex"}>
                    <NavLink to="/" className={"button button-secondary"}>
                        {isMobile ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className={"icon-path"}>
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                        </svg> : 'Watchlist'}
                    </NavLink>
                    <NavLink to="/groups" className={"button button-secondary"}>
                        {isMobile ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className={"icon-path"}>
                            <rect x="3" y="3" width="7" height="7"></rect>
                            <rect x="14" y="3" width="7" height="7"></rect>
                            <rect x="14" y="14" width="7" height="7"></rect>
                            <rect x="3" y="14" width="7" height="7"></rect>
                        </svg> : 'Groups'}
                    </NavLink>
                </div>
                    <div className={"flex flex--align-center"}>
                        {avatarPath ? <NavLink to="/account" className={"button-picture"}><img
                            src={avatarPath}
                            alt="Avatar"
                        /></NavLink> : <NavLink to="/account" className={"button button-secondary"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                                                                         fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round"
                                                                                                         strokeLinejoin="round" className={"icon-path"}>
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                        </svg></NavLink>}
                        <button className={"button-toggle"} aria-label="Toggle Dark Mode" onClick={toggleMode}><div className={darkMode ? "button-toggle__handle button-toggle__handle--active" : "button-toggle__handle"}></div> </button>

                    </div>
            </nav> : ''
        }
    }

    return (
        <NavStructure />
    )
}