import { configureStore } from '@reduxjs/toolkit'
import videosReducer from './videosSlice';
import groupsReducer from './groupsSlice';
import metaDataReducer  from './metaDataSlice';
import themeReducer from './themeSlice';

export default configureStore({
    reducer: {
        videoStore: videosReducer,
        groupStore: groupsReducer,
        metaDataStore: metaDataReducer,
        themeStore: themeReducer
    },
})
