import React, { useState, useEffect } from 'react'
import { supabase } from "../utils/supabaseClient";
import Avatar from "../components/Avatar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    selectThemeStore
} from "../store/themeSlice";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet-async";

export default function Account({ session }) {
    const [loading, setLoading] = useState(true)
    const [username, setUsername] = useState(null)
    const [avatar_url, setAvatarUrl] = useState(null)
    const themeStore = useSelector(selectThemeStore);

    useEffect(() => {
        getProfile()
    }, [session])

    async function getProfile() {
        try {
            setLoading(true)
            const user = supabase.auth.user()

            let { data, error, status } = await supabase
                .from('profiles')
                .select(`username, avatar_url`)
                .eq('id', user.id)

            if (error && status !== 406) {
                throw error
            }

            if (data && data[0] && data[0].username) {
                setUsername(data[0].username)
            }
            if (data && data[0] && data[0].avatar_url) {
                setAvatarUrl(data[0].avatar_url)
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    async function updateProfile({ username, avatar_url }) {
        try {
            setLoading(true)
            const user = supabase.auth.user()

            const updates = {
                id: user.id,
                username,
                avatar_url,
                updated_at: new Date(),
            }

            let { error } = await supabase.from('profiles').upsert(updates, {
                returning: 'minimal', // Don't return the value after inserting
            })

            if (error) {
                throw error
            }
        } catch (error) {
            notify("There was an issue updating your profile")
        } finally {
            notify("Profile updated")
            setLoading(false)
        }
    }

    const reactiveDarkMode = () => {
        return themeStore.darkMode ? 'dark' : 'light'
    }

    const notify = (msg) => toast(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: reactiveDarkMode
    });

    return (
        <div className="container">
            <Helmet>
                <title>{username ? 'Glimpse | ' + username : ''}</title>
            </Helmet>
            <div className="form-widget">
                <Avatar
                    url={avatar_url}
                    size={150}
                    onUpload={(url) => {
                        setAvatarUrl(url)
                        updateProfile({username, avatar_url: url}).then(r => console.log("updateProfile", r))
                     }}
                />

                <div>
                    <label htmlFor="email">Email</label>
                    <input id="email" type="text" value={session.user.email} disabled />
                </div>

                <div>
                    <label htmlFor="username">Name</label>
                    <input
                        id="username"
                        type="text"
                        value={username || ''}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className={"button-wrapper"}>
                    <button
                        className="button-primary"
                        onClick={() => updateProfile({ username, avatar_url })}
                        disabled={loading}
                    >
                        {loading ? 'Loading ...' : 'Update'}
                    </button>
                    <button className={"button-secondary"} onClick={() => supabase.auth.signOut()}>
                        Sign Out
                    </button>
                </div>
            </div>
            <ToastContainer
                newestOnTop={false}
                pauseOnFocusLoss
                draggable
                limit={2}
            />
        </div>
    )
}