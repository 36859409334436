import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({

    name: 'videoStore',
    initialState: {
        videos: [],
        videosWatched: [],
        videoGroup: [],
        reloadVideos: false
    },
    reducers: {
        videoAdded(state, action) {
            let videoInArray = state.videos.find( video => video['videoName'] === action.payload.videoName );
            if (!videoInArray) {
                state.videos.push(action.payload)
            }
        },
        videoWatched(state, action) {
            let videoInArray = state.videosWatched.find( video => video['videoName'] === action.payload.videoName );
            if (!videoInArray) {
                state.videosWatched.push(action.payload)
            }
        },
        videoGroup(state, action) {
            state.videoGroup.push(action.payload)
        },
        clearVideos(state) {
            state.videos = []
        },
        clearVideosWatched(state) {
            state.videosWatched = []
        },
        clearVideoGroup(state) {
            state.videoGroup = []
        },
        setReloadVideos(state, action) {
            state.reloadVideos = action.payload
        }
    },
});

export const { videoAdded, videoWatched, videoGroup, clearVideos, clearVideosWatched, clearVideoGroup, setReloadVideos } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectVideoStore = state => state.videoStore;

export default slice.reducer;
