import { useParams } from 'react-router-dom'
import {supabase} from "../utils/supabaseClient";
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import '../style/components/list.scss'
import '../style/components/card-link.scss'
import VideoCard from "../components/VideoCard";
import Modal from "react-modal";
import {setReloadVideos} from "../store/videosSlice";
import {useDispatch} from "react-redux";

export default function VideoDetail({ session }) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    let params = useParams();
    const [scores, setScores] = useState([])
    const [ownScore, setOwnScore] = useState(undefined)
    const [video, setVideo] = useState(null)
    const [group, setGroup] = useState({})
    const [isAdmin, setIsAdmin] = useState(false)
    const [uploadUserId, setUploadUserId] = useState(null)
    const [uploadUserName, setUploadUserName] = useState(null)
    const [modalDeleteIsOpen, setDeleteIsOpen] = React.useState(false);

    useEffect(() => {
        Modal.setAppElement('body');
    }, [session])

    useEffect(() => {
        getVideo().then(setLoading(false))
    }, [session])

    useEffect(() => {
        getScores()
    }, [session])

    useEffect(() => {
        getVideoAdmins()
    }, [group])

    useEffect(() => {
        getUploadUserInfo()
    }, [session, uploadUserId])

    function openModalDelete() {
        setDeleteIsOpen(true);
    }

    function closeModalDelete() {
        setDeleteIsOpen(false);
    }

    async function getVideo() {
        try {
            const { data } = await supabase
                .from('videos')
                .select('*, profiles!inner(*), group!inner(*)')
                .eq('id', params.videoId)

            setVideo(data[0])
            setUploadUserId(data[0].created_by)
            setGroup(data[0].group)

        } catch (error) {
            console.log("an error occurred", error)
        }
    }

    async function getScores() {
        try {

            const user = supabase.auth.user()

            const { data } = await supabase
                .from('video_user_data')
                .select('*, profiles!inner(*)')
                .eq('video_id', params.videoId)

            for (let entry = 0; entry < data.length; entry++) {
                if (data[entry].rating_score !== null) {
                    setScores(scores => [...scores, {userId: data[entry].uuid, userName: data[entry].profiles.username, ratingScore: data[entry].rating_score}])
                }
                if (data[entry].uuid === user.id) {
                    setOwnScore(data[entry].rating_score)
                    break
                }

                if ((entry === data.length - 1) && (ownScore === undefined)) {
                    setOwnScore(null)
                }
            }

        } catch (error) {
            console.log("an error occurred", error)
        }
    }

    async function getUploadUserInfo() {
        if (uploadUserId !== null) {
            try {
                const { data } = await supabase
                    .from('profiles')
                    .select(`username`)
                    .eq('id', uploadUserId)
                    .single()

                setUploadUserName(data.username)

            } catch (error) {
                console.log("an error occurred", error)
            }
        }
    }

    async function getVideoAdmins() {
        setIsAdmin(false)

        if (group && group.group_id) {
            try {
                const user = supabase.auth.user()

                const { data } = await supabase
                    .from('group_access')
                    .select('*, uuid!inner(*)')
                    .eq('group_id', group.group_id)

                for (let count in data) {
                    if (data[count].uuid.id === user.id && data[count].group_admin) setIsAdmin(true)
                }

            } catch (error) {
                console.log("an error occurred", error)
            }
        }
    }

    async function deleteVideoAccessData() {
        setLoading(true)
        try {
            await supabase
                .from('video_user_data')
                .delete()
                .eq('video_id', params.videoId)
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
            await deleteVideo()
        }
    }

    async function deleteVideo() {
        try {
            await supabase
                .from('videos')
                .delete()
                .eq('id', params.videoId)
        } catch (error) {
            console.log("an error occurred", error)
        } finally {
           setLoading(false)
           window.history.back()
            dispatch(
                setReloadVideos(true)
            )
        }
    }

    const scoreList = scores.map((score) => (
        <div key={score.userId} className={"entry entry--spaced"}><div>{score.userName}</div><div>{score.ratingScore}</div></div>
    ))

    class AverageScore extends React.Component {
        render(){
            let total = 0;
            for(let i = 0; i < scores.length; i++) {
                total += scores[i].ratingScore;
            }
            let averageScore = Math.round(total / scores.length * 10) / 10
            if (isNaN(averageScore)) {
                averageScore = "-"
            }
            return <div className={"list__subline"}><h4>Average Score</h4><h4>{averageScore}</h4></div>;
        }
    }

    return (
        <div className={"container container--flex"}>
            <div className={"container-row container-row--fill"}>

                {video && video.video_url && (ownScore !== undefined) && group ? <VideoCard isCard={false} groupId={group.group_id} key={video.id} session={session} embedId={video.video_url} videoId={video.id} createdAt={video.created_at} ratingScore={ownScore}/> : ''}

                {isAdmin ? <div className={"button-wrapper"}><button className="button-primary" onClick={openModalDelete}>Delete video</button></div> : ''}
            </div>
            <div className={"container-row container-row--fill"}>
                <p>uploaded by {uploadUserName}</p>
                <Link to={"/groups/"+`${group.group_url}`} key={group.group_id} className={"card-link"} state={{ groupId: group.group_id }}><span>in {group.group_name}</span></Link>
                {scores.length > 0 ?
                    <div className={"list"}>
                    <h3>Scores</h3>
                    <AverageScore />
                    {scoreList}
                    </div>
                : ''}
            </div>

            <Modal
                isOpen={modalDeleteIsOpen}
                onRequestClose={closeModalDelete}
                contentLabel="Desc Modal"
                className={"modal-overlay"}
            >
                <div className={"modal"}>
                    <h2>Are you sure?</h2>
                    <p>Once you've deleted your video, the video and all the ratings will be gone.</p>

                    <div className={"button-wrapper"}>
                        <button className="button-secondary" onClick={closeModalDelete}>Close</button>
                        <button
                            className="button-primary"
                            onClick={() => deleteVideoAccessData()}
                            disabled={loading}
                        >
                            {loading ? 'Loading ...' : 'Delete'}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}