import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({

    name: 'groupStore',
    initialState: {
        groups: [],
        yourGroups: [],
        yourGroupIds: [],
        currentGroup: []
    },
    reducers: {
        groupAdded(state, action) {
            let groupInArray = state.groups.find( group => group['groupId'] === action.payload.groupId );
            if (!groupInArray) {
                state.groups.push(action.payload)
            }
        },
        yourGroupUpdate(state, action) {
            let yourGroupInArray = state.yourGroups.find( group => group['groupId'] === action.payload.groupId );
            if (!yourGroupInArray) {
                state.yourGroups.push(action.payload)
                state.yourGroupIds.push(action.payload.groupId)
            }
        },
        currentGroupUpdate(state, action) {
            state.currentGroup.push(action.payload)
        },
        clearGroups(state) {
            state.groups = []
        },
        clearYourGroups(state) {
            state.yourGroups = []
            state.yourGroupIds = []
        },
        clearCurrentGroup(state) {
            state.currentGroup = []
        },
    },
});

export const { groupAdded, yourGroupUpdate, currentGroupUpdate, clearGroups, clearYourGroups, clearCurrentGroup } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectGroupsStore = state => state.groupStore;

export default slice.reducer;
