import './style/index.scss'
import React, { useState, useEffect } from 'react'
import { supabase } from './utils/supabaseClient'
import Auth from './routes/Auth'
import Nav from "./components/Nav";
import Watchlist from "./routes/Watchlist";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Account from "./routes/Account";
import Groups from "./routes/Groups";
import Group from "./routes/Group";
import VideoDetail from "./routes/VideoDetail";
import {yourGroupUpdate} from "./store/groupsSlice";
import {setMetaData} from "./store/metaDataSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectGroupsStore} from "./store/groupsSlice";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Home() {
  const [session, setSession] = useState(null)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch();
  const groupsStore = useSelector(selectGroupsStore);

  useEffect(() => {
    setSession(supabase.auth.session())
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

    useEffect(() => {
        getYourGroups().then()
    }, [session, groupsStore.yourGroupIds])

    useEffect(() => {
        getYourMetaData().then()
    }, [])

    async function getYourGroups() {
        try {
            setLoading(true)

            const user = supabase.auth.user()

            if (user) {
                let { data, error, status } = await supabase
                    .from('group_access')
                    .select('*, group_id!inner(*)')
                    .eq('uuid', user.id)

                if (error && status !== 406) {
                    throw error
                }

                if (data) {
                    for (let group in data) {

                        let groupedData = await supabase
                            .from('group_topic_relations')
                            .select('*, group_topics!inner(*)')
                            .eq('group_id', data[group].group_id.group_id)

                        let groupTopicsArray = []

                        for (let tagIndex in groupedData.data) {
                            groupTopicsArray.push(groupedData.data[tagIndex].group_topics)
                        }

                        dispatch(
                            yourGroupUpdate({
                                groupId: data[group].group_id.group_id,
                                groupUrl: data[group].group_id.group_url,
                                groupName: data[group].group_id.group_name,
                                groupTags: groupTopicsArray,
                                createdAt: data[group].group_id.created_at,
                                createdBy: data[group].group_id.created_by
                            })
                        )
                    }
                }
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    async function getYourMetaData() {
        try {
            setLoading(true)

            const user = supabase.auth.user()

            if (user) {
                let { data, error, status } = await supabase
                    .from('video_user_data')
                    .select('video_id')
                    .eq('uuid', user.id)

                if (error && status !== 406) {
                    throw error
                }

                if (data) {
                    for (let entry in data) {
                        dispatch(
                            setMetaData({videoId: data[entry].video_id})
                        )
                    }
                }
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>glimpse.wtf</title>
                <meta
                    name="description"
                    content="Hot shit videos"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content="https://s3.eu-central-1.amazonaws.com/okaybueno.com/_ptWideSXxl/okay-bueno-workshop-founders.jpg" />
                <meta
                    name="twitter:title"
                    content="Glimpse | Collect videos together"
                />
                <meta name="twitter:creator" content="@okaybueno" />
                <meta name="twitter:site" content="@okaybueno" />
                <meta
                    name="twitter:description"
                    content="glimpse.wtf | Collect videos together"
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="www.okaybueno.com" />
                <meta
                    property="og:title"
                    content="Glimpse"
                />
                <meta
                    property="og:description"
                    content="glimpse.wtf | Collect videos together"
                />
                <meta
                    property="og:image"
                    content="https://s3.eu-central-1.amazonaws.com/okaybueno.com/_ptWideSXxl/okay-bueno-workshop-founders.jpg"
                />
            </Helmet>
            <BrowserRouter>
                {session ? <Nav /> : ''}
                <Routes>
                    <Route path="/" element={!session ? <Auth /> : <Watchlist key={session.user.id} session={session} />} />
                    <Route path="account" element={!session ? <Auth /> : <Account key={session.user.id} session={session} />} />
                    <Route path="groups" element={!session ? <Auth /> : <Groups key={session.user.id} session={session} />} />
                    <Route path="groups/:groupUrl" element={!session ? <Auth /> : <Group key={session.user.id} session={session}/>} />
                    <Route path="videos/:videoId" element={!session ? <Auth /> : <VideoDetail key={session.user.id} session={session} />} />
                </Routes>
            </BrowserRouter>
        </HelmetProvider>
  )
}