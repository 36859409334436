import React, {useEffect, useState} from "react";
import Moment from 'moment';
import PropTypes from "prop-types";
import { supabase } from '../utils/supabaseClient'
import '../style/components/videocard.scss';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearVideos, clearVideosWatched} from "../store/videosSlice";
import {selectGroupsStore} from "../store/groupsSlice";


export default function VideoCard({ createdAt, embedId, videoId, videoWatched, ratingScore
, isCard, groupId }) {
    const [currentRating, setCurrentRating]  = useState(ratingScore)
    const [canRate, setCanRate]  = useState(false)
    const [scores, setScores] = useState([])
    const [ownScore, setOwnScore] = useState(undefined)
    const [avgScore, setAvgScore]  = useState(null)
    const dispatch = useDispatch();
    const groupsStore = useSelector(selectGroupsStore);

    useEffect(() => {
        checkCanVote()
    }, [groupsStore])

    useEffect(() => {
        getScores().then(getAvgRating)
    }, [ratingScore, currentRating])

    useEffect(() => {
        setAvgRating()
    }, [currentRating])

    async function checkVideoUserTable() {
        const user = supabase.auth.user()

        try {
            const { data } = await supabase
                .from('video_user_data')
                .select('*')
                .match({'uuid': user.id, 'video_id': videoId})

            if (data.length > 0) {
                await toggleWatched()
            } else {
                await createRating(currentRating)
            }

        } catch (error) {
            console.log(error)
        }
    }

    async function toggleWatched() {

        const user = supabase.auth.user()
        try {
            await supabase
                .from('video_user_data')
                .update([{ video_id: videoId, uuid: user.id, watched: !videoWatched}])
                .match({'uuid': user.id, 'video_id': videoId})

        } catch (error) {
            console.log(error)
        } finally {
            dispatch(
                clearVideosWatched()
            )
            dispatch(
                clearVideos()
            )
        }
    }

    async function checkRatingTable(score) {
        const user = supabase.auth.user()

        try {
            const { data } = await supabase
                .from('video_user_data')
                .select('*')
                .match({'uuid': user.id, 'video_id': videoId})

            if (data.length > 0) {
                await updateRating(score)
            } else {
                await createRating(score)
            }

        } catch (error) {
            console.log(error)
        }
    }

    async function updateRating(score) {
        const user = supabase.auth.user()

        try {
            await supabase
                .from('video_user_data')
                .update([{ rating_score: parseInt(score)}])
                .match({'uuid': user.id, 'video_id': videoId})
            await setCurrentRating(parseInt(score));
        } catch (error) {
            console.log(error)
        } finally {
            await setAvgRating()
        }
    }

    async function createRating(score) {
        const user = supabase.auth.user()

        try {
            await supabase
                .from('video_user_data')
                .insert([{ rating_score: parseInt(score), video_id: videoId, uuid: user.id, watched: videoWatched}])
            await setCurrentRating(parseInt(score));
        } catch (error) {
            console.log(error)
        } finally {
            await setAvgRating()
        }
    }

    async function setAvgRating() {

        let total = 0;
        for(let i = 0; i < scores.length; i++) {
            total += scores[i].ratingScore;
        }
        let averageScore = Math.round(total / scores.length * 10) / 10
        if (isNaN(averageScore)) {
            averageScore = null
        }
    }

    async function checkCanVote() {

        setCanRate(false)

        const user = supabase.auth.user()

        let matchFormula = groupId ? {'uuid': user.id, 'group_id': groupId} : {'uuid': user.id}

        if (videoId && groupsStore && groupsStore.yourGroupIds) {
            try {

                const { data } = await supabase
                    .from('group_access')
                    .select('*')
                    .in('group_id', groupsStore.yourGroupIds)
                    .match(matchFormula)

                if (data && data.length > 0) setCanRate(true)

            } catch (error) {
                console.log("an error occurred", error)
            }
        }
    }

    async function getScores() {
        try {

            const user = supabase.auth.user()

            const { data } = await supabase
                .from('video_user_data')
                .select('*, profiles!inner(*)')
                .eq('video_id', videoId)

            setScores([])

            for (let entry in data) {
                if (data[entry].rating_score !== null) {
                    setScores(scores => [...scores, {userId: data[entry].uuid, userName: data[entry].profiles.username, ratingScore: data[entry].rating_score}])
                }
                if (data[entry].uuid === user.id) {
                    setOwnScore(data[entry].rating_score)
                    break
                }
            }

            if (ownScore === undefined) {
                setOwnScore(null)
            }

        } catch (error) {
            console.log("an error occurred", error)
        }
    }

    async function getAvgRating() {
        try {
            const { data } = await supabase
                .from('videos')
                .select('avg_score')
                .eq('id', videoId)
                .single()

            await setAvgScore(data.avg_score)

        } catch (error) {
            console.log(error)
        }
    }

    class WatchButtonText extends React.Component {
        render() {
            return videoWatched ? <span>Watched</span> : <span>Mark as watched</span>
        }
    }

    class ReturnDate extends React.Component {
        render(){
            Moment.locale('en');
            let dt = createdAt;
            return(isCard ? <div className={"label"}>{Moment(dt).format('DD.MM.YYYY')}</div> : '')
        }
    }

    class AverageScore extends React.Component {
        render(){
            return <div className={"label"}>Score: {avgScore}</div>;
        }
    }

    function returnButtonClass (userScore) {
        return userScore === currentRating ? 'button-primary' : 'button-secondary'
    }

    return (
        <div className={isCard ? "video-card" : "video-card video-card--flat"}>
            <div className="video-responsive">
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${embedId}`}
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            <div className={"subrow"}>
                <ReturnDate/>
                {videoWatched !== undefined ? <button type="button" className="button-secondary watched" onClick={checkVideoUserTable}><WatchButtonText/></button> : ''}
            </div>
            {canRate ?             <div className="rating-score">
                <button type="button" value="1" className={returnButtonClass(1)} onClick={(e) => checkRatingTable(e.target.value)}>1</button>
                <button type="button" value="2" className={returnButtonClass(2)} onClick={(e) => checkRatingTable(e.target.value)}>2</button>
                <button type="button" value="3" className={returnButtonClass(3)} onClick={(e) => checkRatingTable(e.target.value)}>3</button>
                <button type="button" value="4" className={returnButtonClass(4)} onClick={(e) => checkRatingTable(e.target.value)}>4</button>
                <button type="button" value="5" className={returnButtonClass(5)} onClick={(e) => checkRatingTable(e.target.value)}>5</button>
                <button type="button" value="6" className={returnButtonClass(6)} onClick={(e) => checkRatingTable(e.target.value)}>6</button>
                <button type="button" value="7" className={returnButtonClass(7)} onClick={(e) => checkRatingTable(e.target.value)}>7</button>
                <button type="button" value="8" className={returnButtonClass(8)} onClick={(e) => checkRatingTable(e.target.value)}>8</button>
                <button type="button" value="9" className={returnButtonClass(9)} onClick={(e) => checkRatingTable(e.target.value)}>9</button>
                <button type="button" value="10" className={returnButtonClass(10)} onClick={(e) => checkRatingTable(e.target.value)}>10</button>
            </div> : ''}

            {isCard ? <div className={"video-card__spaced-row"}><Link to={`/videos/${videoId}`} className={'button button-secondary'}>View more</Link><AverageScore /></div> : ''}
        </div>
    )
}

VideoCard.propTypes = {
    videoId: PropTypes.number.isRequired,
    embedId: PropTypes.string.isRequired,
    session: PropTypes.object.isRequired,
    videoWatched: PropTypes.bool,
    ratingScore: PropTypes.number,
    createdAt: PropTypes.string,
    isCard: PropTypes.bool,
    groupId: PropTypes.number,
}