import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({

    name: 'themeStore',
    initialState: {
        darkMode: false
    },
    reducers: {
        setTheme(state, action) {
            state.darkMode = action.payload
        }
    },
});

export const { setTheme } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectThemeStore = state => state.themeStore;

export default slice.reducer;
