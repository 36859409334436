import React, { useState } from 'react'
import { supabase } from '../utils/supabaseClient'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    selectThemeStore
} from "../store/themeSlice";
import {useSelector} from "react-redux";

export default function Auth() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const themeStore = useSelector(selectThemeStore)

    const handleLogin = async (email) => {
        try {
            setLoading(true)
            const { error } = await supabase.auth.signIn({ email })
            if (error) throw error
            notify("Check your email for the login link :)")
        } catch (error) {
            notify("Uh oh! Something went wrong.")
        } finally {
            setLoading(false)
        }
    }

    const reactiveDarkMode = () => {
        return themeStore.darkMode ? 'dark' : 'light'
    }

    const notify = (msg) => toast(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: reactiveDarkMode
    });

    return (
        <div className="container container--auth">
            <div className="card card--rainbow">
                <div className={"card__headline"}>
                    <svg width="43" height="24" viewBox="0 0 43 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M21.2903 0C11.8112 0 0 7.83871 0 12C0 16.0645 11.8112 24 21.2903 24C30.7694 24 42.5806 16.1613 42.5806 12C42.5806 7.93548 30.7694 0 21.2903 0ZM25.5026 6.89756L22.279 8.75871C21.6204 8.06827 20.6786 8.00017 19.6805 8.5764C18.0745 9.50367 17.8489 11.2324 19.0245 13.2686C20.2167 15.3336 21.7677 16.0595 23.4196 15.1057C24.8479 14.2811 25.1931 13.2099 24.6323 12.1724L22.4985 13.4043L21.1871 11.1329L26.3954 8.12588L27.3359 9.7549C29.1904 12.967 28.074 16.1201 24.9995 17.8952C21.5694 19.8755 17.916 18.9257 15.77 15.2088C13.5313 11.3313 14.8426 7.66797 18.0089 5.83993C20.7851 4.23709 23.8949 4.68248 25.5026 6.89756Z" className={"logo-path"} />
                    </svg>
                    <h2>Ready?</h2>
                </div>
                <p>Sign into glimpse.wtf via magic link with your email below and glimpse the shit out of the interwebz.</p>
                <input
                    className="inputField"
                    type="email"
                    placeholder="Your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className={"button-wrapper"}>
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            handleLogin(email)
                        }}
                        className={'button button-primary'}
                        disabled={loading}
                    >
                        {loading ? 'Loading' : 'Send it like Nyjah'}
                    </button>
                </div>
            </div>
            <ToastContainer
                newestOnTop={false}
                pauseOnFocusLoss
                draggable
                limit={1}
            />
        </div>
    )
}